import React from "react";
import { Auth0User, Auth0UserMetadata, Profile } from "../@types/data";
import { TvStudioStageState } from "../@types/generated/types";
import {
  STAGES_COMPONENTS,
  StudioStagesConfig
} from "../providers/StagesProvider";
import { VoiceControlsType } from "../stateManagement/types/studio.types";
import { baseColours, colours, darkBgColours } from "../themes/colours";
export interface InterfaceProfileContext {
  profile: Profile;
  hasRole: boolean;
  setProfile: React.Dispatch<React.SetStateAction<Profile | null>>;
  isAuthenticated: boolean;
  isLoading: boolean;
  isTokenLoading: boolean;
  authProvider: "auth0";
  token: React.MutableRefObject<string | undefined>;
  logout: () => void;
  refreshTokenCallback: () => void;
  userData: Auth0User;
  updateUserMetadata: (updatedValue: Auth0UserMetadata) => void;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const ProfileContext = React.createContext<InterfaceProfileContext>({});

export enum STATE_FILTERS {
  DONE = "DONE",
  IN_PROGRESS = "IN_PROGRESS",
  NO_FILTERS = "NO_FILTERS",
  REQUIRES_VOICE_ACTOR = "REQUIRES_VOICE_ACTOR"
}

export enum ISSUE_FILTERS {
  WITH = "WITH",
  WITHOUT = "WITHOUT",
  NO_FILTERS = "NO_FILTERS"
}

export interface InterfaceTheme {
  bgColours: {
    [key in keyof typeof darkBgColours]: string;
  };
  colours: {
    [key in keyof typeof colours]: string;
  };
  baseColours: {
    [key in keyof typeof baseColours]: string;
  };
  isDark: boolean;
  toggle: () => void;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const ThemeContext = React.createContext<InterfaceTheme>({});
export interface InterfaceSessionContext {
  heartbeatSuccess: boolean;
  setIsIdle: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionTimeoutValue: React.Dispatch<React.SetStateAction<number>>;
  totalTime: number | undefined;
  isIdle: boolean;
  idleTracking: boolean;
}

// @ts-ignore The provider sets the values, so we pass an empty object here
export const SessionContext = React.createContext<InterfaceSessionContext>({});

export type StudioStagesConfigObj = {
  config: StudioStagesConfig;
  isConfigLoading: boolean;
};

export interface StudioFeatureFlags {
  areSegmentRowTimingsEnabled: boolean;
  areAudibleOverlapsControlsEnabled: boolean;
  isBatchSegmentPollingEnabled: boolean;
  isCompleteAllSegmentsButtonEnabled: boolean;
  isTimelineZoomControlsEnabled: boolean;
  isVideoNotesEnabled: boolean;
  isQaPerformanceEnabled: boolean;
  langsWithNoPreviewStep: string[];
  isTranslationMemorySearchEnabled: boolean;
  isQAFeedbackImprovementsEnabled: boolean;
  isMultipleRenditionsEnabled: boolean;
  isMultiTranslationModalEnabled: boolean;
  isCorrectionTypesEnabled: boolean;
  isStudioStagesFlagEnabled: boolean;
  isProsodyBuffetEnabled: boolean;
  studioStagesConfig: StudioStagesConfigObj;
  areStudioCatToolsEnabled: boolean;
  isSessionTimeoutModalEnabled: boolean;
  isSpeakerNameMappingEnabled: boolean;
  isCharacterMappingEnabled: boolean;
  isXttsCustomTextMarkupEnabled: boolean;
  isXlptControlsEnabled: boolean;
  isRemoveSegmentsAutoScrollingEnabled: boolean;
  xlptControls: VoiceControlsType[];
  speakerMapping: {
    [key: string]: string;
  };
}

export const StudioFeatureFlagContext = React.createContext<StudioFeatureFlags>(
  // @ts-ignore The provider sets the values, so we pass an empty object here
  {}
);

export type StagesProps = {
  isComponentInCurrStage: (
    componentId: (typeof STAGES_COMPONENTS)[number]
  ) => boolean;
  isStudioStagesEnabled: boolean;
  currTvStudioStage: TvStudioStageState;
  nextStage: TvStudioStageState | null;
};

// @ts-ignore The provider sets the values, so we pass an empty object here
export const StagesContext = React.createContext<StagesProps>({});

export interface ActivitiesContextProps {
  currYearAndMonth: string; // Format: YYYY-MM
  setCurrYearAndMonth: React.Dispatch<React.SetStateAction<string>>;
}

export const ActivitiesContext = React.createContext<ActivitiesContextProps>(
  // @ts-ignore The provider sets the values, so we pass an empty object here
  {}
);
